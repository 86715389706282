import * as React from "react"
import Layout from "../components/common/layout"
import Title from "../components/title"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Faqs from "../components/faqs";
import SubMenu from "../components/common/sub-menu"
  const FaqsPage = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const dataFAQ = _data.find((item: any) => item.block === 'faqs')
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  return (
      <Layout>
        <Helmet
          title='FAQ'
        />
         <SubMenu data={subMenu.data[0]}></SubMenu>
        <Title data={dataTitle.data[0]} background="bg-white" ></Title>
        <Faqs data={dataFAQ.data[0]}></Faqs>
        <Faqs data={dataFAQ.data[1]}></Faqs>
        <Faqs data={dataFAQ.data[2]}></Faqs>
        <Faqs data={dataFAQ.data[3]}></Faqs>
      </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["faqs"]}}) {
      nodes {
        jsonName
        data {
          block
            data {
              sub_menu{
                title
                links {
                  id
                  text
                  link
                  active
                }
              }
              title_data {
                title
                content
                links{
                  id
                  text
                  url
                }
              }
              faq {
                name
                faq_cta
                faq_link
                questions {
                  id
                  faq_title
                  faq_contents
                }
              }
            }
        }
      }
    }
  }
`
export default FaqsPage
